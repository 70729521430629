export default [
  {
    path: '/:pathMatch(.*)*',
    redirect: "/erro"
  },
  {
    path: '/erro',
    name: 'erro',
    component: () => import('../components/app/erro/Erro.vue'),
    meta: ({
      title: 'Essa página não existe... | OligoCheck',
    })
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/app/login/Login.vue'),
    meta: ({
      title: 'Área Admin | OligoCheck',
    })
  },
  {
    path: '/',
    name: 'painel',
    component: () => import('../components/app/painel/Painel.vue'),
    meta: ({
      logged: true,
      title: 'Painel Admin | OligoCheck',
    })

  },

]
