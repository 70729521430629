<template>
  <section class="modal" :class="{ mostrar: storePainel.mostrarModalEditar }">
    <div class="quadro" v-if="storePainel.clinic">
      <div class="titulo">
        <h2>Editar Clínica</h2>
        <button @click="fecharModal">Fechar</button>
      </div>
      <label>Nome da Clínica</label>
      <input v-model="storePainel.clinic.name" type="text" />
      <label>Endereço</label>
      <input v-model="storePainel.clinic.address" type="text" />
      <label>Link para Instagram</label>
      <input v-model="storePainel.clinic.instagram" type="text" />
      <label>Link para Whatsapp</label>
      <input v-model="storePainel.clinic.whatsapp" type="text" />
      <label>Telefone</label>
      <input v-model="storePainel.clinic.contact" type="text" />
      <label>Foto de Perfil</label>
      <input type="file" @change="onFileChange" />
      <button class="salvar" @click="saveClinic">SALVAR CLÍNICA</button>
      <button class="excluir" @click="deleteClinic">EXCLUIR CLÍNICA</button>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useStorePainel } from "@stores";
import { doc, getDoc, updateDoc, deleteDoc, collection, getDocs } from "@firebase/firestore";
import { db, storage } from "../../../../firabaseConfig";
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";

const storePainel = useStorePainel();

const file = ref(null);

function fecharModal() {
  storePainel.mostrarModalEditar = false;
}

function onFileChange(even) {
  file.value = event.target.files[0];
}

async function saveClinic() {
  try {
    const clinicId = storePainel.clinic.id;
    if (file.value) {
      const fileName = `${new Date().getTime()}_${file.value.name}`;
      const storageReference = storageRef(storage, `clinics/${fileName}`);
      await uploadBytes(storageReference, file.value);
      const logoUrl = await getDownloadURL(storageReference);
      storePainel.clinic.logo = logoUrl;
    }

    const clinicRef = doc(db, "clinics", clinicId);
    await updateDoc(clinicRef, storePainel.clinic);

    alert("Clínica atualizada com sucesso!");
    fecharModal();
    window.location.reload();
  } catch (error) {
    console.error("Erro ao atualizar clínica:", error);
  }
}

async function deleteClinic() {
  try {
    const clinicId = storePainel.clinic.id;
    const clinicRef = doc(db, 'clinics', clinicId);
    await deleteDoc(clinicRef);

    fecharModal();
    // Atualizar a lista de clínicas localmente
    const clinicsCollection = collection(db, 'clinics');
    const clinicSnapshot = await getDocs(clinicsCollection);
    storePainel.clinics = clinicSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    window.location.reload();
  } catch (error) {
    console.error('Erro ao excluir clínica:', error);
  }
}

onMounted(async () => {
  if (storePainel.clinicId) {
    const clinicRef = doc(db, 'clinics', storePainel.clinicId);
    const clinicSnap = await getDoc(clinicRef);
    if (clinicSnap.exists()) {
      storePainel.clinic = clinicSnap.data();
    } else {
      console.error('Clínica não encontrada');
    }
  }
});
</script>

<style scoped>
section.modal {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  min-height: 100dvh;
  max-height: 100dvh;
  height: 100dvh;
  width: 100%;
  background-color: #041521c3;
  overflow-y: scroll;
  padding: 40px 20px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s;
}
section.modal.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.quadro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 500px;
  background-color: #0a2232;
  padding: 40px;
  border-radius: 20px;
}
.titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 2px solid #ffffff21;
  padding: 0 0 20px 0;
}
.titulo h2 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}
.titulo button {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-vermelho);
  background-color: transparent;
}
label {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 20px 0 10px 0;
}
input {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  background-color: #0d2739;
  padding: 15px;
  border-radius: 10px;
  width: 100%;
  transition: all 0.3s;
}
input:focus {
  background-color: #133248;
}
button.salvar {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  background-color: var(--cor-ciano);
  padding: 15px;
  border-radius: 10px;
  width: 100%;
  margin: 20px 0 0 0;
  transition: all 0.3s;
}
button.salvar:hover {
  opacity: 0.7;
}
button.excluir {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  background-color: var(--cor-vermelho);
  padding: 15px;
  border-radius: 10px;
  width: 100%;
  margin: 10px 0 0 0;
  transition: all 0.3s;
}
button.excluir:hover {
  opacity: 0.7;
}
</style>
