<template>
  <Alerta />
  <RouterView />
</template>

<script setup>
import { inject, onMounted } from 'vue'
import { useStoreAlerta } from '@stores'
import Alerta from '@components/global/alerta/Alerta.vue'

const emitter = inject("emitter")
const storeAlerta = useStoreAlerta()

function mostrarAlerta(payload) {
  const dados = {
    visivel: true,
    mensagem: payload.mensagem,
    classe: payload.tipo
  }
  storeAlerta.mostrarAlerta(dados)
}

onMounted(() => {
  emitter.on('mostrarAlerta', (payload) => mostrarAlerta(payload))
})
</script>
