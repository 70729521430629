<template>
  <section class="clinicas">
    <div class="item" v-for="clinic in filterdClinics" :key="clinic.id">
      <div class="foto">
        <img :src="clinic.logo" :alt="clinic.name">
      </div>
      <div class="nome">
        <h3>{{ clinic.name }}</h3>
      </div>
      <button class="whatsapp" @click="abrirModal(clinic)">
        <p>Editar</p>
      </button>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useStorePainel } from '@stores';
import { collection, getDocs } from "@firebase/firestore"
import { db } from "../../../../firabaseConfig";

const storePainel = useStorePainel()
const clinics= ref([])

function abrirModal(clinic) {
  storePainel.mostrarModalEditar = true
  storePainel.clinic = clinic;
}

onMounted(async () => {
  const clinicsCollection = collection(db, 'clinics');
  const clinicSnapshot = await getDocs(clinicsCollection);
  clinics.value = clinicSnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
});

const filterdClinics = computed(() => clinics.value);


</script>

<style scoped>
section.clinicas {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 80px 80px 80px;
  position: relative;
  background-color: var(--cor-escuro-2);
}
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--cor-escuro-3);
  border-radius: 10px;
  padding: 15px 170px 15px 15px;
  position: relative;
  width: 100%;
  margin: 0 0 20px 0;
}
.item .foto {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border: 2px solid var(--cor-azul);
  border-radius: 50%;
}
.item .foto img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.item button.whatsapp {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-azul);
  text-align: center;
  width: 100%;
  max-width: 150px;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--cor-escuro-4);
  border-radius: 10px;
  transition: all 0.3s;
}
.item button.whatsapp img {
  width: 100%;
  max-width: 15px;
  margin: 0 10px 0 0;
}
.item button.whatsapp:hover {
  background-color: var(--cor-escuro-5);
}
.item button.instagram {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: var(--cor-escuro-4);
  border-radius: 10px;
  margin: 0 0 0 20px;
  transition: all 0.3s;
}
.item button.instagram img {
  width: 100%;
  max-width: 15px;
}
.item button.instagram:hover {
  background-color: var(--cor-escuro-5);
}
.item button.telefone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: var(--cor-escuro-4);
  border-radius: 10px;
  transition: all 0.3s;
}
.item button.telefone img {
  width: 100%;
  max-width: 15px;
}
.item button.telefone:hover {
  background-color: var(--cor-escuro-5);
}
.item .nome {
  display: flex;
  align-items: center;
  padding: 0 0 0 15px;
  margin-right: auto;
}
.item .nome h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}
@media screen and (max-width: 1000px) {
  section.clinicas {
    padding: 50px 20px 60px 20px;
  }
  .item {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 20px;
  }
  .item .foto {
    margin: 0 0 20px 0;
  }
  .item button.whatsapp {
    padding: 10px 20px;
    margin: 0 0 0 10px;
    height: 50px;
    width: auto;
    max-width: auto;
    position: relative;
    bottom: auto;
    right: auto;
  }
  .item button.instagram {
    margin: 0 0 0 10px;
  }
  .item .nome {
    padding: 0 0 20px 15px;
    margin-right: 0;
    width: calc(100% - 55px);
  }
}
</style>
