<template>
  <div id="alerta" :class="{ [classe]: true, mostrar: visivel }" @click="storeAlerta.fecharAlerta()">
    <p>{{ mensagem }}</p>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useStoreAlerta } from '@stores'

const storeAlerta = useStoreAlerta()
const { classe, visivel, mensagem } = storeToRefs(storeAlerta)

</script>

<style scoped>
div#alerta {
  display: none;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  position: fixed;
  top: 20px;
  left: 50%;
  background-color: var(--cor-branco);
  transform: scale(1) rotate(0deg) translate(-50%, 0);
  padding: 0 25px;
  height: 70px;
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  animation: animacao 0.5s ease-in-out;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 10000;
}

@keyframes animacao {
  0% {
    transform: scale(0.9) rotate(10deg) translate(-50%, 0);
    opacity: 0;
    visibility: hidden;
  }

  100% {
    visibility: visible;
    pointer-events: all;
    transform: scale(1) rotate(0deg) translate(-50%, 0);
  }
}

div#alerta.mostrar {
  display: flex;
}

svg {
  width: 15px;
  border-radius: 2px;
  margin: 2px 10px 0 0;
}

p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
  line-height: 1.5;
  text-align: left;
  width: 100%;
}

div#alerta.positivo {
  background-color: #28b148c6;
  border-left: 10px solid #226b33;
  box-shadow: 10px 20px 30px 0 #0640141f;
}

div#alerta.positivo svg.um {
  display: flex;
}

div#alerta.positivo svg.dois {
  display: none;
}

div#alerta.negativo {
  background-color: #d4233bb4;
  border-left: 10px solid #861e2b;
  box-shadow: 10px 20px 30px 0 #38070d1f;
}

div#alerta.negativo svg.um {
  display: none;
}

div#alerta.negativo svg.dois {
  display: flex;
}

div#alerta {
  border-left: 10px solid #424242;
  box-shadow: 10px 20px 30px 0 #3636361f;
}

div#alerta svg {
  fill: var(--cor-branco-fixo);
}

div#alerta svg.um {
  display: none;
}

div#alerta svg.dois {
  display: flex;
}
@media screen and (max-width: 1000px) {
  div#alerta {
    width: calc(100% - 40px);
  }
}
</style>
