<template>
  <section class="nav">
    <img src="@images/logo-branco.svg" />
    <button @click="logout">Sair</button>
    <button class="destaque" @click="abrirModal()">CADASTRAR CLÍNICA</button>
  </section>
</template>

<script setup>
import { useStorePainel } from "@stores";
import { signOut } from "firebase/auth";
import { auth } from "../../../../firabaseConfig";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const storePainel = useStorePainel();
const router = useRouter();
const store = useStore();

function abrirModal() {
  storePainel.mostrarModalCriar = true;
}

async function logout() {
  try {
    await signOut(auth);
    store.commit("setAdminLoggedIn", false); // Atualiza o estado Vuex para não logado
    localStorage.removeItem("isAdminLoggedIn"); // Remove o estado de login do localStorage
    router.push("/login");
  } catch (err) {
    console.error("Logout error:", err);
  }
}
</script>

<style scoped>
section.nav {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px 80px;
  background: linear-gradient(90deg, #010d18, #022538);
  z-index: 5;
}
img {
  width: 100%;
  max-width: 130px;
  margin-right: auto;
}
button {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  background-color: transparent;
  padding: 5px 20px 0 0;
}
button.destaque {
  font-family: var(--bold);
  padding: 15px 25px;
  border-radius: 50px;
  background-color: var(--cor-ciano);
  transition: all 0.3s;
}
button.destaque:hover {
  background-color: var(--cor-ciano-escuro);
}
@media screen and (max-width: 1000px) {
  section.nav {
    padding: 15px 20px;
  }
  img {
    max-width: 100px;
  }
  button.destaque {
    display: flex;
    font-size: var(--f1);
    padding: 13px 20px;
  }
}
</style>
