// vue & pinia
import { createApp } from 'vue'
import { createPinia } from 'pinia'

// app & router
import App from './App.vue'
import router from './router'
import store from './store'
// css global
import './css/global.css'

// libs
import mitt from 'mitt'
import VueNumberFormat from 'vue-number-format'
import VueTheMask from 'vue-the-mask'

// instâncias & configurações

const configVueNumberFormat = {
  prefix: '',
  decimal: ',',
  thounsand: '.',
  isInteger: false
}

const app = createApp(App)
const emitter = mitt()

app.use(router)
.use(store)
  .use(createPinia())
  .use(VueTheMask)
  .use(VueNumberFormat, configVueNumberFormat)
  .provide('emitter', emitter)
  .mount('#app')
