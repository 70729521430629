// /* ---- Imports ---- */

// import { createRouter, createWebHistory } from 'vue-router'
// import store from '../store';
// /* ---- Routes ---- */

// import app from './app'

// /* ---- System ---- */

// const router = createRouter({
//   history: createWebHistory(),
//   routes: [
//     ...app
//   ]
// })

// router.beforeEach((to, from, next) => {
//   const isLoggedIn = store.getters.isAdminLoggedIn;

//   if (to.meta.logged && !isLoggedIn) {
//     next('/');
//   } else {
//     next();
//   }
// });



// router.afterEach((to) => { document.title = to.meta.title })

// export default router


// src/router/index.js

import { createRouter, createWebHistory } from 'vue-router';
import store from '../store'; // Vuex Store para verificar o estado de login

import app from './app'; // Suas rotas definidas

const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...app, // Rotas definidas no módulo `app`
  ],
});

// Middleware de autenticação
router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters.isAdminLoggedIn; // Verifica se está logado

  // Se a rota exigir autenticação e o usuário não estiver logado, redirecione para /login
  if (to.meta.logged && !isLoggedIn) {
    next('/login');
  } else {
    next(); // Permite acesso à rota
  }
});

// Define o título da página dinamicamente
router.afterEach((to) => {
  document.title = to.meta.title || 'OligoCheck';
});

export default router;
