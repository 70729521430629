<template>
  <section class="apresentacao" id="apresentacao">
    <div class="conteudo">
      <h2><span>{{ totalClinics }}</span> CLÍNICAS</h2>
      <p>Administre as clínicas cadastradas na plataforma Oligo</p>
    </div>
    <img src="@images/divisor.png" class="divisor">
  </section>
</template>

<script setup>
  import { ref, onMounted  } from "vue";
  import { collection, getDocs } from "firebase/firestore";
  import { db } from "../../../../firabaseConfig";

  const totalClinics = ref(0)

  onMounted(async () => {
    const clinicsColletion = collection(db, 'clinics')
    const clinicSnapshot = await getDocs(clinicsColletion)
    totalClinics.value = clinicSnapshot.size
  })
</script>

<style scoped>
  section.apresentacao {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 120px 80px 110px 80px;
    position: relative;
    background-image: url('@images/background.png');
    background-size: cover;
    background-position: center;
  }
  .conteudo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 700px;
  }
  .tag h3 {
    font-family: var(--regular);
    font-size: var(--f2);
    color: var(--cor-branco);
  }
  h2 {
    font-family: var(--estilo);
    font-size: var(--f5);
    color: var(--cor-branco);
    text-align: center;
    margin: 20px 0 10px 0;
  }
  h2 span {
    color: var(--cor-azul);
  }
  p {
    font-family: var(--regular);
    font-size: var(--f2);
    color: var(--cor-branco);
    text-align: center;
    line-height: 1.5;
  }
  img.divisor {
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
  }
  @media screen and (max-width: 1000px) {
    section.apresentacao {
      padding: 110px 20px 60px 20px;
    }
    .tag img {
      max-width: 70px;
    }
    .tag h3 {
      font-size: var(--f1);
    }
    h2 {
      font-size: var(--f4);
      width: 100%;
      max-width: 250px;
    }
    p {
      font-size: var(--f1);
    }
  }
</style>
