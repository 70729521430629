<template>
  <section class="apresentacao" id="apresentacao">
    <div class="conteudo">
      <div class="tag">
        <img src="@images/icones-pessoas.png">
        <h3>+ de 12.000 usuários en el mundo</h3>
      </div>
      <h2>Esta página no existe</h2>
      <p>La página que estás intentando acceder no existe, inténtalo de nuevo.</p>
      <a href="/">VOLTAR</a>
    </div>
    <img src="@images/divisor.png" class="divisor">
  </section>
</template>

<script setup>

</script>

<style scoped>
  section.apresentacao {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 160px 80px 150px 80px;
    position: relative;
    background-image: url('@images/background.png');
    background-size: cover;
    background-position: center;
    min-height: 100dvh;
  }
  .conteudo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 600px;
  }
  .tag {
    display: flex;
    align-items: center;
  }
  .tag img {
    width: 100%;
    max-width: 100px;
    margin: 0 10px 0 0;
  }
  .tag h3 {
    font-family: var(--regular);
    font-size: var(--f2);
    color: var(--cor-branco);
  }
  h2 {
    font-family: var(--estilo);
    font-size: var(--f5);
    color: var(--cor-branco);
    text-align: center;
    margin: 20px 0 10px 0;
  }
  h2 span {
    color: var(--cor-azul);
  }
  p {
    font-family: var(--regular);
    font-size: var(--f2);
    color: var(--cor-branco);
    text-align: center;
    line-height: 1.5;
    margin: 0 0 20px 0;
  }
  a {
    font-family: var(--bold);
    font-size: var(--f2);
    color: var(--cor-branco);
    padding: 20px 50px;
    border-radius: 50px;
    background-color: var(--cor-ciano);
    transition: all 0.3s;
  }
  a:hover {
    background-color: var(--cor-ciano-escuro);
  }
  img.divisor {
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
  }
  @media screen and (max-width: 1000px) {
    section.apresentacao {
      padding: 130px 20px 90px 20px;
    }
    .tag img {
      max-width: 70px;
    }
    .tag h3 {
      font-size: var(--f1);
    }
    h2 {
      font-size: var(--f4);
      width: 100%;
      max-width: 250px;
    }
    p {
      font-size: var(--f1);
      width: 100%;
      max-width: 270px;
    }
    .input img {
      left: 20px;
      max-width: 13px;
    }
    .input input {
      padding: 20px 100px 20px 45px;
    }
    .input button {
      font-size: var(--f1);
      padding: 0 25px;
    }
  }
</style>
