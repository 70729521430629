<template>
  <section class="apresentacao" id="apresentacao">
    <div class="conteudo">
      <h2>ÁREA <span>ADMIN</span></h2>
      <p>Clínica más cerca para realizarse un análisis con Oligocheck</p>
      <form @submit.prevent="login">
        <input
          type="text"
          spellcheck="false"
          placeholder="email@email.com"
          v-model="email"
          required
        />
        <input
          type="password"
          spellcheck="false"
          placeholder="Sua senha"
          v-model="password"
          required
        />
        <button type="submit">ENTRAR NO ADMIN</button>
      </form>
      <p v-if="error" class="error">{{ error }}</p>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { auth } from "../../../../firabaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useStore } from "vuex"

const email = ref("");
const password = ref("");
const error = ref("");
const router = useRouter();
const store = useStore();

async function login() {
  try {
    await signInWithEmailAndPassword(auth, email.value, password.value);
    store.commit('setAdminLoggedIn', true);
    router.push("/");
  } catch (err) {
    error.value = err.message;
  }
}
</script>

<style scoped>
section.apresentacao {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 160px 80px 150px 80px;
  position: relative;
  background-image: url("@images/background.png");
  background-size: cover;
  background-position: center;
  min-height: 100dvh;
}
.conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
}
h2 {
  font-family: var(--estilo);
  font-size: var(--f5);
  color: var(--cor-branco);
  text-align: center;
  margin: 20px 0 10px 0;
}
h2 span {
  color: var(--cor-azul);
}
p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  text-align: center;
  line-height: 1.5;
  margin: 0 0 20px 0;
  width: 100%;
}

form {
  width: 100%;
  max-width: 400px;
}

input {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  background-color: var(--cor-escuro-3);
  padding: 20px 30px;
  border-radius: 50px;
  width: 100%;
  max-width: 400px;
  margin: 0 0 20px 0;
}
button {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  background-color: var(--cor-ciano);
  padding: 20px;
  border-radius: 50px;
  width: 100%;
  max-width: 400px;
  transition: all 0.3s;
}
button:hover {
  background-color: var(--cor-ciano-escuro);
}
@media screen and (max-width: 1000px) {
  section.apresentacao {
    padding: 130px 20px 90px 20px;
  }
  .tag img {
    max-width: 70px;
  }
  .tag h3 {
    font-size: var(--f1);
  }
  h2 {
    font-size: var(--f4);
    width: 100%;
    max-width: 250px;
  }
  p {
    font-size: var(--f1);
    width: 100%;
    max-width: 230px;
  }
  .input img {
    left: 20px;
    max-width: 13px;
  }
  .input input {
    padding: 20px 100px 20px 45px;
  }
  .input button {
    font-size: var(--f1);
    padding: 0 25px;
  }
}
</style>
