<template>
  <section class="footer">
    <div class="copy">
      <img src="@images/logo-branco.svg"/>
      <p>Plataforma de prevención y bienestar</p>
    </div>
    <div class="social">
      <p>Nuestras redes sociales</p>
      <a href="">
        <img src="@images/icone-facebook.svg">
      </a>
      <a href="">
        <img src="@images/icone-instagram.svg">
      </a>
      <a href="">
        <img src="@images/icone-whatsapp.svg">
      </a>
    </div>
  </section>
</template>

<script setup>

</script>

<style scoped>
  section.footer {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 60px 80px 60px 80px;
    background-color: var(--cor-escuro-2);
  }
  .copy {
    display: flex;
    align-items: center;
    margin-right: auto;
  }
  .copy img {
    width: 100%;
    max-width: 130px;
  }
  .copy p {
    font-family: var(--regular);
    font-size: var(--f2);
    color: var(--cor-branco);
    margin: 0 0 0 20px;
  }
  .social {
    display: flex;
    align-items: center;
  }
  .social p {
    font-family: var(--regular);
    font-size: var(--f2);
    color: var(--cor-branco);
    margin: 0 10px 0 0;
  }
  .social a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    background-color: var(--cor-escuro-4);
    margin: 0 0 0 10px;
  }
  .social a:hover {
    background-color: var(--cor-escuro-5);
  }
  .social a img {
    width: 100%;
    max-width: 25px;
  }
  @media screen and (max-width: 1000px) {
    section.footer {
      flex-direction: column;
      padding: 40px 20px 60px 20px;
    }
    .copy {
      flex-direction: column;
      margin-right: 0;
    }
    .copy p {
      margin: 10px 0 0 0;
    }
    .social {
      padding: 25px 0 0 0;
    }
    .social p {
      display: none;
    }
  }
</style>
