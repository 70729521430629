// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDkUw6dxrO1TZipL8vL2PJEN3kWWUoRMbU",
    authDomain: "system-oligocheck.firebaseapp.com",
    projectId: "system-oligocheck",
    storageBucket: "system-oligocheck.appspot.com",
    messagingSenderId: "1035094961879",
    appId: "1:1035094961879:web:d6b9e7ecd65df315950a92"
};

// Inicializando o Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
