import { createStore } from 'vuex';

export default createStore({
  state: {
    isAdminLoggedIn: JSON.parse(localStorage.getItem('isAdminLoggedIn')) || false, // Restaura do localStorage
  },
  mutations: {
    setAdminLoggedIn(state, status) {
      state.isAdminLoggedIn = status;
      localStorage.setItem('isAdminLoggedIn', JSON.stringify(status)); // Salva no localStorage
    },
  },
  getters: {
    isAdminLoggedIn: (state) => state.isAdminLoggedIn,
  },
});
